import { UPDATE_SUB_ADMIN, UPDATE_SUPERADMIN_PASS } from "./types";
import { OPEN_SUB_ADMIN_DIALOGUE } from "./types";
import { GET_SUPER_ADMIN } from "./types";
import { CREATE_SUPER_ADMIN } from "./types";
import { CLOSE_SUB_ADMIN_DIALOGUE } from "./types";
import { DELETE_SUB_ADMIN } from "./types";
import { CREATE_SUB_ADMIN } from "./types";
import { GET_SUB_ADMIN } from "./types";
 
  const initialState = {
    superAdmin: [],
    total: 0,
    dialog: false,
    dialogData: null,
  };
  
  const superAdminReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_SUPER_ADMIN:
        return {
          ...state,
          superAdmin: action.payload,
        };
      case CREATE_SUPER_ADMIN:
        let data = [...state.superAdmin];
        data?.unshift(action.payload);
  
        return {
          ...state,
          superAdmin: data,
        };
      case UPDATE_SUB_ADMIN:
        return {
          ...state,
          superAdmin: state.superAdmin.map((item) =>
            item?._id === action?.payload?.id ? action?.payload?.data : item
          ),
        };

        case UPDATE_SUPERADMIN_PASS:
          return {
            ...state,
            superAdmin: state.superAdmin.map((item) =>
              item?._id === action?.payload?.id ? action?.payload?.data : item
            ),
          };
  
      case DELETE_SUB_ADMIN:
        return {
          ...state,
          superAdmin: state.superAdmin.filter(
            (superAdmin) => superAdmin._id !== action?.payload
          ),
        };
      case OPEN_SUB_ADMIN_DIALOGUE:
        return {
          ...state,
          dialog: true,
          dialogData: action.payload || null,
        };
      case CLOSE_SUB_ADMIN_DIALOGUE:
        return {
          ...state,
          dialog: false,
          dialogData: null,
        };
  
      default:
        return state;
    }
  };
  
  export default superAdminReducer;
  