import * as ActionType from "./type";

let dialogData = localStorage.getItem("data");
let parsedIntData = JSON.parse(dialogData);

const initialState = {
  dialog: parsedIntData ? parsedIntData.dialog : false,
  type: parsedIntData ? parsedIntData.type : "",
  dialogData: parsedIntData ? parsedIntData.data : null,

};

export const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.OPEN_dialog:
      console.log("dialog",action.payload);
      console.log("dialog",state);
      return {
        ...state,
        dialog: true,
        type: action.payload.type || "",
        dialogData: action.payload.dialogData || null,
        extraData: action.payload.extraData || null,
      };
    case ActionType.CLOSE_dialog:
      return {
        ...state,
        dialog: false,
        type: "",
        dialogData: null,
        extraData: null,
      };


    default:
      return state;
  }
};
