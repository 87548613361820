import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHostWiseLiveHistory } from "../store/agency/action";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";

import $ from "jquery";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DateRangePicker } from 'react-date-range';
import dayjs from 'dayjs';
import Male from '../assets/images/male.png';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const HostWiseLiveHistory = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { hostLiveHistory, totalHostLiveHistory } = useSelector(
    (state) => state.agency
  );

  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");
  const maxDate = new Date();

  const hostId = location.state?._id;

  // Get first and last day of current month
  const getCurrentMonthDates = () => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
      .toISOString()
      .split("T")[0];
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      .toISOString()
      .split("T")[0];
    return { firstDay, lastDay };
  };

  useEffect(() => {
    const { firstDay, lastDay } = getCurrentMonthDates();
    dispatch(getHostWiseLiveHistory(hostId, firstDay, lastDay, page, limit));
  }, [dispatch, hostId, page, limit]);

  const collapsedDatePicker = () => {
    $("#datePicker").toggleClass("collapse");
  };

  const handleFetchData = () => {
    dispatch(getHostWiseLiveHistory(hostId, sDate, eDate, page, limit));
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-light" style={{ color: "#e4eeff" }}>
              Host  live history
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Host Live history
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                    <button className="btn btn-info" style={{ marginRight: 5 }}>
                      All
                    </button>
                    <button
                      className="collapsed btn btn-info ml-5"
                      value="check"
                      data-toggle="collapse"
                      data-target="#datePicker"
                      onClick={collapsedDatePicker}
                    >
                      Analytics
                      <ExpandMoreIcon />
                    </button>
                    <p style={{ paddingLeft: 10 }} className="my-2">
                      {sDate !== "ALL" && sDate + " to " + eDate}
                    </p>
                  </div>
                </div>
                <div
                  id="datePicker"
                  className="collapse mt-5 pt-5"
                  aria-expanded="false"
                >
                  <div className="container table-responsive">
                    <div key={JSON.stringify(date)}>
                      <DateRangePicker
                        maxDate={maxDate}
                        onChange={(item) => {
                          const { startDate, endDate } = item.selection;
                          if (startDate && endDate) {
                            setDate([item.selection]);
                            setsDate(dayjs(startDate).format("YYYY-MM-DD"));
                            seteDate(dayjs(endDate).format("YYYY-MM-DD"));
                          }
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        direction="horizontal"
                      />
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={handleFetchData}
                    >
                      Fetch Data
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body card-overflow pt-0">
              <table className="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>UniqueId</th>
                    <th>Type</th>
                    <th>Live duration</th>
                    <th>Valid</th>
                  </tr>
                </thead>
                <tbody>
                  {hostLiveHistory?.length > 0 ? (
                    hostLiveHistory.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{(page - 1) * limit + index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data?.host?.image ? data?.host?.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                              className="mx-auto"
                            />
                          </td>
                          <td>
                            {data?.host?.name
                              ? capitalizeFirstLetter(
                                  data?.host?.name.length > 15
                                    ? data?.host?.name.substring(0, 15) + "..."
                                    : data?.host?.name
                                )
                              : "-"}
                          </td>
                          <td>
                            {data?.host?.uniqueId ? data?.host?.uniqueId : "-"}
                          </td>
                          <td className="text-danger">
                            {data?.audio ? "Audio" : data?.video ? "Video" : "-"}
                          </td>
                          <td>{data?.liveDuration}</td>
                          <td>
                            {data?.isValid ? (
                              <CheckCircleIcon style={{ color: '#4CAF50' }} />
                            ) : (
                              <CancelIcon style={{ color: '#f44336' }} />
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* Add your Pagination component here */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostWiseLiveHistory;
