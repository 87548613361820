import { GET_HOST_LIVE_HISTORY } from "./type";

const initialState = {
    hostLiveHistory: [],
};

const hostLiveHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOST_LIVE_HISTORY:
      return { ...state, hostLiveHistory: action.payload };
    default:
      return state;
  }
};

export default hostLiveHistoryReducer;
