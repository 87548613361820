import axios from "axios";
import { Toast } from "../../util/Toast";

import { apiInstanceFetch } from "../../util/api";

import { toast } from "react-toastify";
import {
  CREATE_SUPER_ADMIN,
  DELETE_SUB_ADMIN,
  GET_SUPER_ADMIN,
  UPDATE_SUB_ADMIN,
  UPDATE_SUPER_ADMIN,
  UPDATE_SUPERADMIN_PASS,
} from "./types";

export const getSuperAdmin = (search) => (dispatch) => {
  apiInstanceFetch
    .get(`superadmin/getAll?search=${search}`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: GET_SUPER_ADMIN, payload: res.data });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => {
      Toast("error", error.message);
    });
};

export const addSuperAdmin = (data) => (dispatch) => {
  axios
    .post(`/superadmin/create`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: CREATE_SUPER_ADMIN, payload: res.data.data });
        Toast("success", "Super Admin Insert Successful");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const deleteSubAdmin = (id) => (dispatch) => {
  axios
    .delete(`admin/deleteSubAdmin?adminId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: DELETE_SUB_ADMIN,
          payload: id,
        });
        toast("success", "Sub Admin delete Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error.message);
    });
};

export const updateSubAdmin = (data, id) => (dispatch) => {
  axios
    .patch(`admin/updateSubAdmin?adminId=${id}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_SUB_ADMIN,
          payload: { data: res.data.admin, id: id },
        });
        Toast("success", "Sub Admin Update Successful");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const updateSuperAdmin = (data, id) => (dispatch) => {
  axios
    .patch(`superadmin/update?adminId=${id}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_SUPER_ADMIN,
          payload: res.data.data,
        });
        Toast("success", "Super Admin Update Successful");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error.message);
    });
};


export const updatePassword = (data, id) => (dispatch) => {
  axios
    .patch(`superadmin/updatePassword?superAdminId=${id}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_SUPERADMIN_PASS,
          payload: res.data.data,
        });
        Toast("success", "Super Admin password Update Successful");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error.message);
    });
};