// GET COINSELLER
export const GET_SUPERSELLER = "GET_SUPERSELLER";
export const GET_COINSELLER_UNIQUEID = "GET_COINSELLER_UNIQUEID";

// ADD COINSELLER
export const ADD_SUPERSELLER = "ADD_SUPERSELLER";

// EDIT COINSELLER
export const EDIT_COINSELLER = "EDIT_COINSELLER";

// DELETE COINSELLER
export const DELETE_SUPERSELLER = "DELETE_SUPERSELLER";

// OPEN DIALOG

export const OPEN_DIALOGUE = "OPEN_DIALOGUE";

// CLOSE DIALOG
export const CLOSE_DIALOGUE = "CLOSE_DIALOGUE";

// ADD_MONEY_OPEN_DIALOGUE

export const ADD_MONEY_OPEN_DIALOGUE = "ADD_MONEY_OPEN_DIALOGUE";

// ADD_MONEY_CLOSE_DIALOGUE
export const ADD_MONEY_CLOSE_DIALOGUE = " ADD_MONEY_CLOSE_DIALOGUE";


export const LESS_MONEY_OPEN_DIALOGUE = "LESS_MONEY_OPEN_DIALOGUE";

// LESS_MONEY_CLOSE_DIALOGUE
export const LESS_MONEY_CLOSE_DIALOGUE = " LESS_MONEY_CLOSE_DIALOGUE";

// add money
export const ADD_MONEY_BY_SUPERSELLER = "ADD_MONEY_BY_SUPERSELLER";

// less money
export const LESS_MONEY_BY_ADMIN = "LESS_MONEY_BY_ADMIN";

// Show COINSELLER
export const SHOW_COINSELLER = "SHOW_COINSELLER";

// add money
export const GET_COINSELLER_HISTORY = "GET_COINSELLER_HISTORY";

export const GET_SUPERSELLER_HISTORY = "GET_SUPERSELLER_HISTORY";


// coin seller dialogue
export const OPEN_COINSELLER_DIALOGUE = "OPEN_COINSELLER_DIALOGUE"

export const ADD_MOBILE_OPEN_DIALOGUE = "ADD_MOBILE_OPEN_DIALOGUE";

// ADD_MONEY_CLOSE_DIALOGUE
export const ADD_MOBILE_CLOSE_DIALOGUE = " ADD_MOBILE_CLOSE_DIALOGUE";


export const MOBILE_NUMBER_BY_ADMIN = "MOBILE_NUMBER_BY_ADMIN";


export const UPDATE_PASSWORD = "UPDATE_PASSWORD";

