import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@material-ui/core";
import { useEffect, useState } from "react";
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux";
import { getCoinSellerUniqueId } from "../../store/coinSeller/action";
import { createNewBd, editBd } from "../../store/bdTarget/action";
import { CLOSE_BD_DIALOG } from "../../store/bdTarget/types";
import { Cancel } from "@material-ui/icons";
import Male from "../../assets/images/male.png"
import $, { error } from "jquery"
import { set } from "date-fns";

const BdDialogue = () => {
    const dispatch = useDispatch();
    const { coinSellerId } = useSelector((state) => state.coinSeller);

    const { dialog: open, dialogData, bd } = useSelector((state) => state.bd);

    const [imageData, setImageData] = useState([]);
    const [imagePath, setImagePath] = useState(null);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [uniqueId, setUniqueId] = useState("");
    const [mongoId, setMongoId] = useState("");
    const [name, setName] = useState("");
    const [bankDetail , setBankDetail] = useState("")

    const [adminCode, setAdminCode] = useState("");

    const [code, setCode] = useState("");

    const [errors, setError] = useState({
        name: "",
        code: "",
        uniqueId: "",
        image: "",
        bankDetail: "",
        adminCode: "",
    });

    useEffect(() => {
        dispatch(getCoinSellerUniqueId(search));
    }, [dispatch, search]);

    useEffect(() => {
        setData(coinSellerId);
    }, [coinSellerId]);

    useEffect(() => {
        if (dialogData) {
            setMongoId(dialogData?._id);
            setName(dialogData?.name);
            setBankDetail(dialogData?.bankDetail)
            setUniqueId(dialogData?.uniqueId);
            setImagePath(dialogData?.image);
            setCode(dialogData?.bdCode);
            setAdminCode(dialogData?.superAdminCode ? dialogData?.superAdminCode : "-");
        }
    }, [dialogData]);

    const removeImage = () => {
        setImageData([]);
        setImagePath(null);
    };
    $(document).ready(function () {
        $("img").bind("error", function () {
            // Set the default image
            $(this).attr("src", Male);
        });
    });

    useEffect(
        () => () => {
            setError({
                name: "",
                code: "",
                image: "",
                adminCode: "",
            });
            setMongoId("");
            setName("");
            setBankDetail("")
            setUniqueId("");
            setCode("");
            setAdminCode("");
            setImageData([]);
            setImagePath(null);
        },
        [open]
    );

    const handleInputImage = (e) => {
        if (e.target.files[0]) {
            setImageData(e.target.files[0]);
            const reader = new FileReader();

            reader.addEventListener("load", () => {
                setImagePath(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
        if (!e.target.files[0]) {
            return setError({
                ...errors,
                image: "Please select an Image!",
            });
        } else {
            return setError({
                ...errors,
                image: "",
            });
        }
    };

    const createCode = () => {
        const randomChars = "0123456789";
        let code_ = "";
        for (let i = 0; i < 4; i++) {
            code_ += randomChars.charAt(
                Math.floor(Math.random() * randomChars.length)
            );
            setCode(code_);
        }
        if (!code_) {
            return setError({
                ...errors,
                code: "Code can't be a blank!",
            });
        } else {
            return setError({
                ...errors,
                code: "",
            });
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };
    const handleSubmit = (e) => {
        if (!name || !code || (!dialogData && !imagePath) || !uniqueId) {
            const errors = {};
            if (!name) {
                errors.name = "Name can't be a blank!";
            }
            if(!bankDetail){
                errors.bankDetail = "Bank Detail can't be a blank!"
            }
            if (!uniqueId) {
                errors.uniqueId = "UniqueId can't be empty!";
            }

            if (!code) {
                errors.code = "Code can't be a blank!";
            }

            if (!dialogData && !imagePath) {
                errors.image = "Please select an Image!";
            }

            return setError({ ...errors });
        }
        if (code.toString().length > 4) {
            return setError({
                ...errors,
                code: "Maximum 4 Digits are Allowed!",
            });
        }

        if (code.toString().length < 4) {
            return setError({
                ...errors,
                code: "Minimum 4 Digits are Allowed!",
            });
        }

        if (!mongoId) {
            const index = bd?.findIndex((bd) => bd?.code?.toString() === code);
            if (index > -1) {
                return setError({ ...errors, code: "Code already exist." });
            }
            // if (imageData.length === 0 || !imagePath) {
            //   return setError({ ...errors, image: "Please select an Image!" });
            // }
        } else {
            const index = bd?.find((bd) => bd?.code?.toString() === code);
            if (index !== undefined) {
                if (index?._id === mongoId) {
                } else {
                    return setError({ ...errors, code: "Code already exist." });
                }
            }
            // if (imageData?.length == 0 || !imagePath) {
            //   return setError({ ...errors, image: "Please select an Image!" });
            // }
        }

        const formData = new FormData();

        formData.append("image", imageData);
        formData.append("name", name);
        formData.append("uniqueId", uniqueId);
        formData.append("bdCode", code);
        formData.append("bankDetails", bankDetail);
        
        if (mongoId) {
            dispatch(editBd(formData, mongoId));
        } else {
            formData.append("superAdminCode", adminCode);
            dispatch(createNewBd(formData));
        }
        closePopup();
    };

    const closePopup = () => {
        dispatch({ type: CLOSE_BD_DIALOG });
    };
    return (
        <Dialog
            open={open}
            aria-labelledby="responsive-dialog-title"
            onClose={closePopup}
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth
            sx={{ maxWidth: "100%", margin: "0 auto" }}
        >
            <DialogTitle id="responsive-dialog-title">
                <span className="text-danger font-weight-bold h4"> BD </span>
            </DialogTitle>

            <IconButton
                style={{
                    position: "absolute",
                    right: 0,
                }}
            >
                <Tooltip title="Close">
                    <Cancel className="text-danger" onClick={closePopup} />
                </Tooltip>
            </IconButton>
            <DialogContent>
                <div className="modal-body pt-1 px-1 pb-3">
                    <div className="d-flex flex-column">
                        <form>
                            <div className="row">
                                {!dialogData && (
                                    <div className="form-group col-12">
                                        <label class="mb-2 text-gray">Unique Id Of User</label>
                                        <Select
                                            value={data.find(
                                                (option) => option?.uniqueId === uniqueId
                                            )}
                                            options={data}
                                            getOptionLabel={(option) => option?.uniqueId} // Assuming uniqueId is the label for options
                                            formatOptionLabel={(option) => (
                                                <div className="country-option">
                                                    <img
                                                        src={option?.image ? option?.image : Male}
                                                        style={{
                                                            height: "30px",
                                                            width: "30px",
                                                            borderRadius: "50%",
                                                        }}
                                                        alt="country-image"
                                                    />
                                                    <span className="ms-3">{option?.uniqueId}</span>
                                                </div>
                                            )}
                                            onChange={(selectedOption) => {
                                                setUniqueId(selectedOption?.uniqueId);
                                                if (
                                                    !selectedOption?.uniqueId ||
                                                    selectedOption?.uniqueId === "uniqueId"
                                                ) {
                                                    setError({
                                                        ...errors,
                                                        uniqueId: "Please select a uniqueId!",
                                                    });
                                                } else {
                                                    setError({
                                                        ...errors,
                                                        uniqueId: "",
                                                    });
                                                }
                                            }}
                                        // onInputChange={(inputValue) => {
                                        //   if (inputValue?.length > 6) {
                                        //     setSearch(inputValue);
                                        //   }
                                        // }}
                                        />
                                        {errors.uniqueId && (
                                            <div className="ml-2 mt-1">
                                                {errors.uniqueId && (
                                                    <div className="pl-1 text__left">
                                                        <span className="text-red">{errors.uniqueId}</span>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div class="form-group col-12 mt-3">
                                    <label class="mb-2 text-gray">Name</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter Name"
                                        required
                                        value={name}
                                        onKeyPress={handleKeyPress}
                                        onChange={(e) => {
                                            setName(e.target.value.trim());

                                            if (!e.target.value) {
                                                return setError({
                                                    ...errors,
                                                    name: "Name can't be a blank!",
                                                });
                                            } else {
                                                return setError({
                                                    ...errors,
                                                    name: "",
                                                });
                                            }
                                        }}
                                    />
                                    {errors.name && (
                                        <div className="ml-2 mt-1">
                                            {errors.name && (
                                                <div className="pl-1 text__left">
                                                    <span className="text-red">{errors.name}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div class="form-group col-12 mt-3">
                                    <label class="mb-2 text-gray">Bank Details</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter Bank Details"
                                        required
                                        value={bankDetail}
                                        onKeyPress={handleKeyPress}
                                        onChange={(e) => {
                                            setBankDetail(e.target.value.trim());

                                            if (!e.target.value) {
                                                return setError({
                                                    ...errors,
                                                    bankDetail: "Name can't be a blank!",
                                                });
                                            } else {
                                                return setError({
                                                    ...errors,
                                                    bankDetail: "",
                                                });
                                            }
                                        }}
                                    />
                                    {errors.bankDetail && (
                                        <div className="ml-2 mt-1">
                                            {errors.bankDetail && (
                                                <div className="pl-1 text__left">
                                                    <span className="text-red">{errors.bankDetail}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>


                                <div class="form-group col-12 mt-3">
                                    <label class="mb-2 text-gray">Superadmin code</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Superadmin code"
                                        required
                                        value={adminCode}
                                        disabled={dialogData && true}
                                        onKeyPress={handleKeyPress}
                                        onChange={(e) => {
                                            setAdminCode(e.target.value.trim());

                                            if (!e.target.value) {
                                                return setError({
                                                    ...errors,
                                                    adminCode: "Admin can't be a blank!",
                                                });
                                            } else {
                                                return setError({
                                                    ...errors,
                                                    adminCode: "",
                                                });
                                            }
                                        }}
                                    />
                                    {errors.adminCode && (
                                        <div className="ml-2 mt-1">
                                            {errors.adminCode && (
                                                <div className="pl-1 text__left">
                                                    <span className="text-red">{errors.adminCode}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className="form-group col-12 mt-4">
                                    <label className="mb-2 text-gray">Image</label>
                                    <input
                                        type="file"
                                        className="form-control form-control-sm"
                                        accept="image/jpg ,image/jpeg ,image/png"
                                        required=""
                                        onChange={handleInputImage}
                                    />
                                    {errors.image && (
                                        <div className="ml-2 mt-1">
                                            {errors.image && (
                                                <div className="pl-1 text__left">
                                                    <span className="text-red">{errors.image}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="form-group col-12  mt-4">
                                    {imagePath && (
                                        <>
                                            <img
                                                height="70px"
                                                width="70px"
                                                alt="app"
                                                src={imagePath}
                                                style={{
                                                    boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                                    // border: "2px solid #fff",
                                                    borderRadius: 10,
                                                    marginTop: 10,
                                                    float: "left",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>

                            <div class="row d-flex mt-4">
                                <div class={`${mongoId ? "col-12" : "col-md-9"}`}>
                                    <div class="form-group">
                                        <label class="mb-2 text-gray">BD Code</label>
                                        <input
                                            readOnly
                                            type="number"
                                            class="form-control"
                                            placeholder="Enter Code"
                                            required
                                            value={code}
                                            onKeyPress={handleKeyPress}
                                            onChange={(e) => {
                                                setCode(e.target.value);

                                                if (!e.target.value) {
                                                    return setError({
                                                        ...errors,
                                                        code: "Code can't be a blank!",
                                                    });
                                                } else {
                                                    return setError({
                                                        ...errors,
                                                        code: "",
                                                    });
                                                }
                                            }}
                                        />
                                        {errors.code && (
                                            <div className="ml-2 mt-1">
                                                {errors.code && (
                                                    <div className="pl-1 text__left">
                                                        <span className="text-red">{errors.code}</span>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {!mongoId && (
                                    <div
                                        class="col-md-3 pl-0 d-flex justify-content-end align-items-center"
                                        style={{ marginTop: "22.01px" }}
                                    >
                                        <button
                                            type="button"
                                            class="btn btn-info"
                                            style={{
                                                borderRadius: 5,
                                                fontSize: "14px",
                                                padding: "8px",
                                            }}
                                            onClick={createCode}
                                        >
                                            Auto Generate
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                                <button
                                    type="button"
                                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                                    onClick={closePopup}
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-round float__right btn-danger"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
export default BdDialogue