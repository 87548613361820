export const GET_SUPER_ADMIN = "GET_SUPER_ADMIN";
export const CREATE_SUPER_ADMIN = "CREATE_SUPER_ADMIN";

export const UPDATE_SUB_ADMIN = "UPDATE_SUB_ADMIN";

export const UPDATE_SUPER_ADMIN = "UPDATE_SUPER_ADMIN";


export const DELETE_SUB_ADMIN = "DELETE_SUB_ADMIN";

export const OPEN_SUB_ADMIN_DIALOGUE = "OPEN_SUB_ADMIN_DIALOGUE";
export const CLOSE_SUB_ADMIN_DIALOGUE = "CLOSE_SUB_ADMIN_DIALOGUE";


export const UPDATE_SUPERADMIN_PASS = "UPDATE_SUPERADMIN_PASS"
