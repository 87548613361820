export const GET_HOST_LIVE_HISTORY = "GET_HOST_LIVE_HISTORY";

export const GET_HOST_LIVE_HISTORY_BY_DATE = "GET_HOST_LIVE_HISTORY_BY_DATE";

export const GET_HOST_LIVE_HISTORY_BY_DATE_AND_TIME =
  "GET_HOST_LIVE_HISTORY_BY_DATE_AND_TIME";
  
export const GET_HOST_LIVE_HISTORY_BY_DATE_AND_TIME_AND_USER =
  "GET_HOST_LIVE_HISTORY_BY_DATE_AND_TIME_AND_USER";

