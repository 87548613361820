import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { getSuperAdmin, updatePassword } from "../../store/superAdmin/action";
import { CLOSE_dialog } from "../../store/dialog/type";
import { shiftAdmin } from "../../store/bdTarget/action";
import { set } from "date-fns";
import { shiftBd } from "../../store/agencyVerfication/action";
import { getAgency } from "../../store/agency/action";

const SwitchBd = () => {
  const { dialog: open, dialogData } = useSelector((state) => state.dialog);

  console.log("dialogData", dialogData);

  const [currentAdmin, setCurrentAdmin] = useState("");
  const [newAdminCode, setNewAdminCode] = useState();

  const dispatch = useDispatch();

  const [errors, setError] = useState("");
  useEffect(
    () => () => {
      setError("");
      setNewAdminCode(null);
    },
    [open]
  );

  useEffect(() => {
    if (dialogData) {
      setCurrentAdmin(
        dialogData?.bd
          ? "Code " +
              " : " +
              dialogData?.bd?.bdCode +
              "       Name : " +
              dialogData?.bd?.name
          : "Not assigned"
      );
    }
  }, [dialogData]);

  const closePopup = () => {
    dispatch({ type: CLOSE_dialog });
  };

  const handleUpdate = () => {
    if (!newAdminCode) {
      return setError("New Bd Code is required");
    } else {
      dispatch(shiftBd(dialogData?._id, newAdminCode));
      setTimeout(() => {
        dispatch(getAgency(1, 10));
      }, 600);
    }
    closePopup();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableEscapeKeyDown
        onKeyPress={handleKeyPress}
        fullWidth
        sx={{ maxWidth: "100%", margin: "0 auto" }}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4">
            {" "}
            Switch/Assign Bd To Agency
          </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Current Bd details</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Current Bd details"
                      required
                      value={currentAdmin}
                      readOnly
                    />
                  </div>
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">New Bd code</label>
                    <input
                      type="number"
                      class="form-control"
                      placeholder="New Bd's Code"
                      required
                      maxLength={4}
                      minLength={4}
                      value={newAdminCode}
                      onChange={(e) => {
                        setNewAdminCode(e.target.value);

                        if (!e.target.value) {
                            return setError("Bd Code  can't be a blank!")
                          } else {
                            return setError("")
                          }
                      }}
                    />
                    {errors.newAdminCode && (
                      <div className="ml-2 mt-1">
                        {errors.newAdminCode && (
                          <div className="pl-1 text__left">
                            <span className="text-red">
                              {errors.newAdminCode}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={"mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={() => handleUpdate()}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SwitchBd;
