import { TablePagination, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Pagination from "../pages/Pagination";
import $ from "jquery";
import Male from "../assets/images/male.png";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getSuperSeller,
  showCoinSeller,
  deleteSuperSeller
} from "../store/superSeller/action";
import {
  ADD_MOBILE_OPEN_DIALOGUE,
  ADD_MONEY_OPEN_DIALOGUE,
  LESS_MONEY_OPEN_DIALOGUE,
  OPEN_COINSELLER_DIALOGUE,
} from "../store/superSeller/type";
import CoinSellerAdd from "../component/dialog/CoinSellerAdd";
import dayjs from "dayjs";
import SuperSellerAddCoin from "../component/dialog/SuperSellerAddCoin";
import { baseURL } from "../util/Config";
import SuperSellerLessCoin from "../component/dialog/SuperSellerLessCoin";
import MobileNumberModel from "../component/dialog/MobileNumberModel";
import SuperSellerDialogue from "../component/dialog/SuperSellerDialogue";
import { OPEN_dialog } from "../store/dialog/type";
import SuperSellerPassword from "../component/dialog/SuperSellerPassword";

const SuperSeller = (props) => {
const TablePaginationActions = React.lazy(() => import("../component/table/TablePagination"));

  const { superSeller, total } = useSelector((state) => state.superSeller);
  const {dialog,type,dialogData} = useSelector((state) => state.dialog);


  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("All");

  console.log("data" , data)


  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getSuperSeller(search));
  }, [search]);

  useEffect(() => {
    setData(superSeller);
  }, [superSeller]);

  useEffect(() => {
    handleSearch();
  }, [search, superSeller]);

  const handleBlockUnblockSwitch_ = (data) => {
    props.liveCut(data.liveStreamingId, data?.liveUserId?._id, data?.username);
  };

  const history = useHistory();

  const handleUserInfo = (user) => {
    history.push({ pathname: "/admin/user/detail", state: user });
  };

  const handleUserHistory = (data) => {
    history.push({
      pathname: "/admin/superSeller/history",
        state:{ data},
    });
  };

  const handleSellerInfo = (data) => {
    history.push({
      pathname: "/admin/superSeller/info",
      state: { data },
    });
  };


  $(document).ready(function () {
    $("img").bind("error", function () {
      $(this).attr("src", Male);
    });
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearch = () => {
    const value = search.trim().toLowerCase();

    if (value) {
      const filteredData = superSeller.filter((data) => {
        return (
          data?.user?.name?.toLowerCase().includes(value) ||
          data?.uniqueId?.toString().includes(value) ||
          data?.coin?.toString().includes(value) ||
          data?.spendCoin?.toString().includes(value)
        );
      });
      setData(filteredData);
    } else {
      setData(superSeller);
    }
  };

  const handleOpen = () => {
    dispatch({ type: OPEN_COINSELLER_DIALOGUE });
  };

  const handleEdit = (data) => {
    dispatch({ type: OPEN_COINSELLER_DIALOGUE, payload: data });
  };

  const handleShow_ = (value) => {
    props.showCoinSeller(value);
  };

  const handleDisable = (value) => {
 dispatch(deleteSuperSeller(value));
  };

  const handleGiveCoin = (value) => {
    dispatch({ type: ADD_MONEY_OPEN_DIALOGUE, payload: value });
  };

  const handleLessCoin = (value) => {
    dispatch({ type: LESS_MONEY_OPEN_DIALOGUE, payload: value });
  };

  const handleGiveMobile = (value) => {
    dispatch({ type: ADD_MOBILE_OPEN_DIALOGUE, payload: value });
  };

  const handlePassword = (data) => {
    dispatch({ type: OPEN_dialog, payload:{ dialogData:data ,type:"superSellerPass"}});
  };



  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3" style={{ color: "#e4eeff" }}>
              Super Seller
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  Super Seller
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <button
                    type="button"
                    className="btn waves-effect waves-light btn-danger btn-sm float-left"
                    onClick={handleOpen}
                    id="CoinSellerAdd"
                  >
                    <i className="fa fa-plus"></i>
                    <span className="icon_margin">New</span>
                  </button>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={(e) => {
                          if (e.target.value.length === 0) {
                            handleSearch(e);
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSearch(e);
                          }
                        }}
                      />
                      <div
                        className="input-group-prepend border-0"
                        for="searchBar"
                        onClick={() => {
                          // Use setSearch with the value of the input field
                          setSearch(document.getElementById("searchBar").value);
                        }}
                      >
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card-body card-overflow pt-0">
              <table className="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Unique Id</th>
                    <th>Coin</th>
                    <th>Code</th>
                    <th>Spend Coin</th>
                    <th>Mobile Number</th>
                    <th>Created At</th>
                    <th>Give Coin</th>
                    <th>Less Coin</th>
                    <th>Edit</th>
                    <th>Is Active</th>
                    <th>History</th>
                    <th>Seller</th>
                    <th>Password</th>
                  </tr>
                </thead>
                <tbody>
                  {superSeller?.length > 0 ? (
                    superSeller.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{page * rowsPerPage + index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data?.user?.image ? data?.user?.image : ""}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                float: "left",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                          <td>{data?.name ? data?.name : "-"}</td>
                          <td>{data?.uniqueId}</td>
                          <td>{data?.coin}</td>
                          <td>{data?.code}</td>
                          <td>{data?.spendCoin}</td>
                          <td>
                            <div
                              className="showEditNumber"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                {data?.mobileNumber
                                  ? (data?.countryCode
                                      ? "+" + data?.countryCode + " "
                                      : "") + data?.mobileNumber
                                  : "-"}
                              </span>
                            </div>
                          </td>
                          <td>
                            {dayjs(data?.createdAt).format("DD MMM YYYY")}
                          </td>
                          <td>
                            <Tooltip title="Give Coin">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => handleGiveCoin(data?._id)}
                              >
                                <i className="fa fa-edit fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="Give Coin">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => handleLessCoin(data?._id)}
                              >
                                <i className="fa fa-edit fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="Give Coin">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => handleEdit(data)}
                              >
                                <i className="fa fa-edit fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data?.isActive}
                                onChange={() => handleDisable(data?._id)}
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${
                                      data?.isActive ? "-22px" : "35px"
                                    }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data?.isActive ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td>
                          <td>
                            <Tooltip title="History">
                              <button
                                type="button"
                                className="btn btn-sm btn-success"
                                onClick={() => handleUserHistory(data)}
                              >
                                <i className="fas fa-history fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>

                          <td>
                            <Tooltip title="Info">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => handleSellerInfo(data)}
                              >
                                <i className="fas fa-info-circle fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() => handlePassword(data)}
                            >
                              Password
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="20" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <TablePagination
                id="pagination"
                component="div"
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  100,
                  { label: "All", value: -1 },
                ]}
                count={superSeller.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                classes="menuItem"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </div>
          </div>
        </div>
      </div>
      <SuperSellerDialogue />
      <SuperSellerAddCoin />
      <SuperSellerLessCoin />
   {  dialog && type === "superSellerPass" && <SuperSellerPassword />}
    </>
  );
};

export default connect(null, {
  getSuperSeller,
  deleteSuperSeller,
  showCoinSeller,
})(SuperSeller);
