import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { getSuperSeller, updatePassword } from "../../store/superSeller/action";
import { CLOSE_dialog } from "../../store/dialog/type";

const SuperSellerPassword = () => {
  const { dialog: open, dialogData } = useSelector((state) => state.dialog);

  console.log("dialogData", dialogData);

  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const [errors, setError] = useState({
    newPass: "",
    password: "",
    confirmPass: "",
  });

  useEffect(
    () => () => {
      setError({
        newPass: "",
        password: "",
        confirmPass: "",
      });
      setPassword("");
      setConfirmPass("");
      setNewPass("");
    },
    [open]
  );

  useEffect(() => {
    if (dialogData) {
      setPassword(dialogData.password);
    }
  }, [dialogData]);

  const closePopup = () => {
    dispatch({ type: CLOSE_dialog });
  };

  const handleUpdate = () => {
    


    if (!password || !confirmPass || !newPass || confirmPass !== newPass || newPass.length < 6) {
      let error = {};
      if (!password) error.password = "This is required field!";
      if (!confirmPass) error.confirmPass = "This is required field!";
      if (!newPass) error.newPass = "This is required field!";
      if(newPass.length < 6) error.newPass = "Password must be at least 6 characters";
      if(confirmPass !== newPass) error.confirmPass = "Password & Confirm Password does not match";
      return setError({ ...error });
    } else {
      const data = {
        oldPass: password,
        newPass,
        confirmPass,
      };
      dispatch(updatePassword(data, dialogData?._id));
      setTimeout(() => {
        dispatch(getSuperSeller("All"));
      }, 600);
    }
    closePopup();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableEscapeKeyDown
        onKeyPress={handleKeyPress}
        fullWidth
        sx={{ maxWidth: "100%", margin: "0 auto" }}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Password </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Current Password</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Current Password"
                      required
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            password: "password can't be a blank!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            password: "",
                          });
                        }
                      }}
                    />
                    {errors.password && (
                      <div className="ml-2 mt-1">
                        {errors.password && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.password}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">New Password</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="New Password"
                      required
                      value={newPass}
                      onChange={(e) => {
                        setNewPass(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            newPass: "newPass can't be a blank!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            newPass: "",
                          });
                        }
                      }}
                    />
                    {errors.newPass && (
                      <div className="ml-2 mt-1">
                        {errors.newPass && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.newPass}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Confirm Password</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Confirm Password"
                      required
                      value={confirmPass}
                      onChange={(e) => {
                        setConfirmPass(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            confirmPass: "confirmPassword can't be a blank!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            confirmPass: "",
                          });
                        }
                      }}
                    />
                    {errors.confirmPass && (
                      <div className="ml-2 mt-1">
                        {errors.confirmPass && (
                          <div className="pl-1 text__left">
                            <span className="text-red">
                              {errors.confirmPass}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={"mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={() => handleUpdate()}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SuperSellerPassword;
