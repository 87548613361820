import React, { useEffect, useState } from "react";
import $ from "jquery";

//redux
import { connect, useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types

//action
import {
  addSuperSeller,
  editCoinSeller,
  getCoinSellerUniqueId,
} from "../../store/superSeller/action";
import { CLOSE_DIALOGUE } from "../../store/coinSeller/type";
import axios from "axios";
import Select from "react-select"

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,

            backgroundColor: isSelected ? "#181821" : "#1f1f2b",
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? "#181821"
                        : "#1f1f2b"
                    : undefined,
            },
            placeholder: (styles) => ({
                ...styles,
                color: "#fdfdfd",
            }),
        };
    },
};


const SuperSellerDialogue = (props) => {
  const dispatch = useDispatch();

  const { dialogOpen: open, dialogData } = useSelector(
    (state) => state.coinSeller
  );
  const { coinSellerId } = useSelector((state) => state.coinSeller);

  const [uniqueId, setUniqueId] = useState("");
  const [name, setName] = useState("");
  const [password , setPassword] = useState("")
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [code, setCode] = useState("");
  const [mongoId, setMongoId] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [country, setCountry] = useState({
        value: "",
        label: "",
    });
  const [countryData, setCountryData] = useState([]);

  const options = countryData.map((countryData) => ({
        label: countryData?.name?.common,
        value: countryData?.name?.common,
    }));

        useEffect(() => {
        axios.get("https://restcountries.com/v3.1/all").then((res) => {
            setCountryData(res.data);
        });
    }, []);


  const [errors, setError] = useState({
    uniqueId: "",
    name: "",
    mobileNumber: "",
    password : "",
    country : ""
  });
  useEffect(() => {
    dispatch(getCoinSellerUniqueId(search));
  }, [dispatch, search]);

  useEffect(() => {
    setData(coinSellerId);
  }, [coinSellerId]);
  const handleChange = (e) => {};

  // const filteredData = data.filter(item => {
  //   return item?.uniqueId.toLowerCase().includes(search.toLowerCase());
  // });

  useEffect(() => {
    setUniqueId("");
    setMongoId("")
    setName("");
    setMobileNumber("");
    setCode("")
    setError({
      name: "",
      countryCode: "",
      mobileNumber: "",
      password : "",
      country : "",
      uniqueId : "",
      code : ""
    });
    setCountry("");
    setPassword("")
  }, [open]);

  useEffect(() => {
    if (dialogData) {
      setUniqueId(dialogData?.uniqueId);
      setMongoId(dialogData?._id);
      setName(dialogData?.name);
      setCountryCode(dialogData?.countryCode);
      setMobileNumber(dialogData?.mobileNumber);
      setPassword(dialogData?.password)
      setCountry({
                value: dialogData?.country,
                label: dialogData?.country,
            });
     setCode(dialogData?.code);
   
    }
  }, [dialogData]);

  const closePopup = () => {
    dispatch({ type: CLOSE_DIALOGUE });
  };

  const handleSubmit = () => {
    if ( !name ||  !mobileNumber ||  !country || !code || !uniqueId) {
      const error = {};
    //   if (!uniqueId) error.uniqueId = "UniqueId Is Required !";
      if (!name) error.name = "Name Is Required !";
      if (!uniqueId) error.uniqueId = "UniqueId is Required !"
      if (!mobileNumber) error.mobileNumber = "Mobile Number Is Required !";
      if (!password) error.password = "Password is Required";
      if (!code) error.code = "Code is Required";
           if(!country) {
                error.country = "Please select a country!"
            }
      return setError({ ...error });
    } else {
      const data = {
        uniqueId: uniqueId,
        name: name,
        mobileNumber: mobileNumber,
        password : password,
        country : country.value,
        code : code
      };
      if (dialogData) {
       dispatch(editCoinSeller(dialogData._id,data));
      } else {
      dispatch(addSuperSeller(data));
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };

      const createCode = () => {
        const randomChars = "0123456789";
        let code_ = "";
        for (let i = 0; i < 4; i++) {
            code_ += randomChars.charAt(
                Math.floor(Math.random() * randomChars.length)
            );
            setCode(code_);
        }
        if (!code_) {
            return setError({
                ...errors,
                code: "Code can't be a blank!",
            });
        } else {
            return setError({
                ...errors,
                code: "",
            });
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };
  const [uniqueIdSearch, setUniqueIdSearch] = useState("");

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Super Seller </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="mb-2 text-gray">
                        Unique Id of User
                      </label>

                      <ReactSelect
                        value={
                          data &&
                          data?.find((option) => option?.uniqueId === uniqueId)
                        }
                        options={data}
                        getOptionLabel={(option) => option?.uniqueId} // Assuming uniqueId is the label for options
                        formatOptionLabel={(option) => (
                          <div className="country-option">
                            <img
                              src={option?.image}
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                              }}
                              alt="country-image"
                            />
                            <span className="ms-3">{option?.uniqueId}</span>
                          </div>
                        )}
                        onChange={(selectedOption) => {
                          setUniqueId(selectedOption?.uniqueId);
                          if (
                            !selectedOption?.uniqueId ||
                            selectedOption?.uniqueId === "uniqueId"
                          ) {
                            setError({
                              ...errors,
                              uniqueId: "Please select a uniqueId!",
                            });
                          } else {
                            setError({
                              ...errors,
                              uniqueId: "",
                            });
                          }
                        }}
                        onInputChange={(inputValue) => {
                          if (inputValue?.length > 12) {
                            setSearch(inputValue);
                          }
                        }}
                      />
                      {errors.uniqueId && (
                        <div className="ml-2 mt-1">
                          {errors.uniqueId && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.uniqueId}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  
                            <div className="col-12 mt-4">
                                <div className="form-group">
                                    <label className="text-gray mb-2">Country</label>

                                    <>

                                        <Select
                                            value={country}
                                            options={options}
                                            className=""
                                            styles={colourStyles}
                                            onChange={(selectedOption) => {
                                                setCountry(selectedOption);
                                                // Handle error
                                            }}
                                        />

                                        {errors.country && (
                                            <div className="ml-2 mt-1">
                                                {errors.country && (
                                                    <div className="pl-1 text__left">
                                                        <span className="text-red">
                                                            {errors.country}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </>
                                </div>
                            </div>
                  <div className="col-12 ">
                    <div className=" form-group">
                      <label className="mb-2 mt-3 text-gray">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        placeholder="Name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              name: "Coin is Required!",
                            });
                          } else if (e.target.value < 0) {
                            return setError({
                              ...errors,
                              name: "Enter Correct Coin !",
                            });
                          } else {
                            return setError({
                              ...errors,
                              name: "",
                            });
                          }
                        }}
                      />
                      {errors.name && (
                        <div className="ml-2 mt-1">
                          {errors.name && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.name}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  
                  {
                    !mongoId && 
                

                     <div className="col-12">
                    <div className=" form-group">
                      <label className="mb-2 mt-3 text-gray">Password</label>
                      <input
                        type="number"
                        className="form-control"
                        required=""
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              password: "Password is Required!",
                            });
                          } else if (e.target.value < 0) {
                            return setError({
                              ...errors,
                              password: "Enter Correct Password !",
                            });
                          } else {
                            return setError({
                              ...errors,
                              password: "",
                            });
                          }
                        }}
                      />
                      {errors.name && (
                        <div className="ml-2 mt-1">
                          {errors.name && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.name}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                    }
                  <div className="col-12 mt-3">
                    <div
                      className="form-group"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <label className="mb-2 text-gray">Mobile Number</label>
                        <input
                          type="number"
                          className="form-control"
                          required=""
                          placeholder="Mobile Number"
                          value={mobileNumber}
                          onChange={(e) => {
                            setMobileNumber(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...errors,
                                mobileNumber: "Mobile Number is Required!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                mobileNumber: "",
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    {errors.countryCode && (
                      <div className="ml-2 mt-1">
                        {errors.countryCode && (
                          <div className="pl-1 text__left">
                            <span className="text-red">
                              {errors.countryCode}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {errors.mobileNumber && (
                      <div className="ml-2 mt-1">
                        {errors.mobileNumber && (
                          <div className="pl-1 text__left">
                            <span className="text-red">
                              {errors.mobileNumber}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                    <div className="row d-flex mt-4">
  {/* Code Input Section */}
  <div className={`${mongoId ? "col-12" : "col-md-7"}`}>
    <div className="form-group">
      <label className="mb-2 text-gray">Code</label>
      <input
        readOnly
        type="number"
        className="form-control"
        placeholder="Enter Code"
        required
        value={code}
        onKeyPress={handleKeyPress}
        onChange={(e) => {
          setCode(e.target.value);

          if (!e.target.value) {
            // Set error if code is empty
            setError({
              ...errors,
              code: "Code can't be a blank!",
            });
          } else {
            // Clear error if code is valid
            setError({
              ...errors,
              code: "",
            });
          }
        }}
      />
      {/* Error Message Display */}
      {errors.code && (
        <div className="ml-2 mt-1">
          <div className="pl-1 text__left">
            <span className="text-red">{errors.code}</span>
          </div>
        </div>
      )}
    </div>
  </div>

  {/* Auto Generate Button */}
  {!mongoId && (
    <div className="col-md-5 d-flex align-items-center">
      <button
        type="button"
        className="btn btn-info p-0 py-2 px-2"
        style={{
          borderRadius: "5px",
          fontSize: "14px",
          marginLeft: "10px",
          marginTop : "25px"
        }}
        onClick={createCode}
      >
        Auto Generate
      </button>
    </div>
  )}
</div>

                            </div>
                <div className={"mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { addSuperSeller, editCoinSeller })(SuperSellerDialogue);
