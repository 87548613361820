import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubAdmin, getSuperAdmin } from "../../store/superAdmin/action";
import { OPEN_SUB_ADMIN_DIALOGUE } from "../../store/superAdmin/types";
import { warning } from "../../util/Alert";
import SuperAdminDialogue from "../dialog/SuperAdminDialogue";
import SuperAdminPassword from "../dialog/SuperAdminPassword";
import Male from "../../assets/images/male.png";
import { baseURL } from "../../util/Config";
import { OPEN_dialog } from "../../store/dialog/type";
import { Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SuperAdmin = () => {
  const dispatch = useDispatch();
  const { dialog, type, dialogData } = useSelector((state) => state.dialog);
  const history = useHistory();
  const { superAdmin } = useSelector((state) => state.superAdmin);
  const [search, setSearch] = useState("All");

  // console.log("superAdmin", superAdmin);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getSuperAdmin(search));
  }, [search]);

  useEffect(() => {
    setData(superAdmin);
  }, [superAdmin]);

  const handleOpen = () => {
    dispatch({ type: OPEN_SUB_ADMIN_DIALOGUE });
  };

  const handleEdit = (data) => {
    dispatch({
      type: OPEN_SUB_ADMIN_DIALOGUE,
      payload: { data: data, type: "" },
    });
  };

  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          dispatch(deleteSubAdmin(id));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSearch = (e) => {
    const value = e?.target?.value.toString()
      ? e?.target?.value?.trim()?.toString()
      : e?.target?.value?.trim();

    if (value) {
      const data = superAdmin.filter((data) => {
        return (
          data?.name?.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          data?.email?.toString()?.indexOf(value.toLowerCase()) > -1 ||
          data?.code?.toString()?.indexOf(value) > -1 ||
          data?.country?.toString()?.indexOf(value.toLowerCase()) > -1
        );
      });
      setData(data);
    } else {
      return setData(superAdmin);
    }
  };

  const handlePassword = (data) => {
    dispatch({
      type: OPEN_dialog,
      payload: { dialogData: data, type: "superAdminPass" },
    });
  };

  return (
    <div>
      <div class="row">
        <div class="col">
          <h3 className="mb-3 text-white">Super Admin</h3>
          <div class="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <button
                    type="button"
                    className="btn waves-effect waves-light btn-danger btn-sm float-left"
                    onClick={handleOpen}
                    id="bannerDialog"
                  >
                    <i className="fa fa-plus"></i>
                    <span className="icon_margin">New</span>
                  </button>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={(e) => {
                          if (e.target.value.length === 0) {
                            handleSearch(e);
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSearch(e);
                          }
                        }}
                      />
                      <div
                        className="input-group-prepend border-0"
                        for="searchBar"
                        onClick={() => {
                          // Use setSearch with the value of the input field
                          setSearch(document.getElementById("searchBar").value);
                        }}
                      >
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table class="table table-striped">
                <thead className="text-white">
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Country</th>
                    <th>Code</th>
                    {/* <th>Bd Count</th>
                    <th>Agency Count</th>
                    <th>Host Count</th> */}
                    <th>Bd</th>
                    <th>Edit</th>
                    <th>Password</th>
                  </tr>
                </thead>
                <tbody className="t">
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data.image ? baseURL + data.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                              className="mx-auto"
                            />
                          </td>
                          <td>{data?.name}</td>
                          <td>{data?.email}</td>
                          <td>{data?.country}</td>
                          <td>{data?.code}</td>
                          {/* <td>{data?.bdCount ? data?.bdCount : 0}</td>
                          <td>{data?.agencyCount ? data?.agencyCount : 0}</td>
                          <td>{data?.hostCount ? data?.hostCount : 0}</td> */}
                          <td>
                            <div className="d-flex justify-content-center">
                              <Tooltip title="Host">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-success d-flex align-items-center"
                                  style={{ backgroundColor: "#fc9494" }}
                                  onClick={() =>
                                    history.push({
                                      pathname: "/admin/superAdmin/bd",
                                      state: data,
                                    })
                                  }
                                >
                                  <i
                                    className="material-icons"
                                    style={{ fontSize: "20px" }}
                                  >
                                    people
                                  </i>
                                </button>
                              </Tooltip>
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() => handleEdit(data)}
                            >
                              Edit
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() => handlePassword(data)}
                            >
                              Password
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SuperAdminDialogue />
      {dialog && type == "superAdminPass" && <SuperAdminPassword />}
    </div>
  );
};
export default SuperAdmin;
