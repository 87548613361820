import axios from "axios";
import { Toast } from "../../../src/util/Toast";
import { apiInstanceFetch } from "../../util/api";
import {
  GET_SUPERSELLER,
  GET_COINSELLER_UNIQUEID,
  ADD_SUPERSELLER,
  EDIT_COINSELLER,
  DELETE_SUPERSELLER,
  SHOW_COINSELLER,
  ADD_MONEY_BY_ADMIN,
  LESS_MONEY_BY_ADMIN,
  GET_COINSELLER_HISTORY,
  MOBILE_NUMBER_BY_ADMIN,
  GET_SUPERSELLER_HISTORY,
  ADD_MONEY_BY_SUPERSELLER,
  UPDATE_PASSWORD
  
} from "./type";


// GET coinSeller
export const getSuperSeller = (search) => (dispatch) => {
  
  apiInstanceFetch
    .get(`superSeller?search=${search}`)
    
    .then((res) => {
      
      dispatch({
        type: GET_SUPERSELLER,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};

// GET coinSeller UniqueId
export const getCoinSellerUniqueId = (search) => (dispatch) => {
  apiInstanceFetch
    .get(`user/getUsersUniqueId?search=${search}`)
    .then((res) => {
      dispatch({
        type: GET_COINSELLER_UNIQUEID,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};

// CREATE coinSeller
export const addSuperSeller = (data) => (dispatch) => {
  axios
    .post(
      `superSeller`,data
    )
    .then((res) => {
      
      if (res.data.status === true) {
        dispatch({
          type: ADD_SUPERSELLER,
          payload: res.data.data,
        });
        Toast("success", "coinSeller Add Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

// UPDATE coinSeller
export const editCoinSeller = (id, data) => (dispatch) => {
  axios
    .patch(`superSeller?superSellerId=${id}` , data)
    .then((res) => {
      
      if (res.data.status === true) {
        dispatch({
          type: EDIT_COINSELLER,

          payload: { superSeller: res.data.data, id },
        });
        Toast("success", "SuperSeller Update Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

// DELETE AGANCY
export const deleteSuperSeller = (id) => (dispatch) => {
  axios
    .patch(`superSeller/isActive?superSellerId=${id}`)
    .then((res) => {
      
      dispatch({
        type: DELETE_SUPERSELLER,
        payload: { data: res.data.data, id: id },
      });
      Toast(
        "success",
        res.data.data?.isActive === true
          ? "Enable Successfully"
          : "disable Successfully"
      );
    })
    .catch((error) => console.log(error));
};

// Show coinSeller
export const showCoinSeller = (id) => (dispatch) => {
  axios
    .patch(`coinSeller/show/${id}`)
    .then((res) => {
      dispatch({
        type: SHOW_COINSELLER,
        payload: { data: res.data.data, id },
      });
    })
    .catch((error) => console.log(error));
};

// UPDATE coinSeller
export const AddMoneyBySuperSeller = (data, id) => (dispatch) => {
  axios
    .patch(`superSeller/assignCoin?superSellerId=${id}` , data)
    .then((res) => {
      if (res.data.status === true) {
        
        dispatch({
          type: ADD_MONEY_BY_SUPERSELLER,
          payload: { coinSeller: res.data.data, id: id },
        });
        Toast("success", "Add coin Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const lessCoinSellerCoin = (data, id) => (dispatch) => {
  axios
    .patch(`coinSeller/coinLessByAdmin?coinSellerId=${id}&coin=${data?.coin}`)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: LESS_MONEY_BY_ADMIN,
          payload: { coinSeller: res.data.data, id },
        });
        Toast("success", "coinSeller Update Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const getSuperSellerHistory = (id, start, limit) => (dispatch) => {
  axios
    .get(
      `superSeller/getSuperSellerHistoryForAdmin?superSellerId=${id}&start=${start}&limit=${limit}`
    )
    .then((res) => {
      
      console.log(res.data);
      dispatch({
        type: GET_SUPERSELLER_HISTORY,
        payload: res.data.data,
      });
    })
    .catch((error) => console.log(error));
};


export const getSuperSellerInfo = (id, start, limit) => (dispatch) => {
  axios
    .get(
      `superSeller/getSellerOfSuperSeller?superSellerId=${id}&start=${start}&limit=${limit}`
    )
    .then((res) => {
      
      console.log(res.data);
      dispatch({
        type: GET_SUPERSELLER_HISTORY,
        payload: res.data.data,
      });
    })
    .catch((error) => console.log(error));
};


// GET coinSeller history
export const getCoinSellerHistory = (id, start, limit) => (dispatch) => {
  axios
    .get(
      `coinSellerHistory/getCoinSellerHistory?coinSellerId=${id}&start=${start}&limit=${limit}`
    )
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: GET_COINSELLER_HISTORY,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};

export const MobileNumberByAdmin = (data, id) => (dispatch) => {
  axios
    .patch(
      `coinSeller/editMobileNumber?coinSellerId=${id}&mobileNumber=${data?.mobileNumber}&countryCode=${data?.countryCode}`
    )
    .then((res) => {
      console.log(res.data.coinSeller);
      if (res.data.status === true) {
        dispatch({
          type: MOBILE_NUMBER_BY_ADMIN,
          payload: { coinSeller: res.data.coinSeller, id: id },
        });
        Toast("success", "Mobile Number Change Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};


export const updatePassword = (data, id) => (dispatch) => {
  axios
    .patch(`superSeller/updatePassword?superSellerId=${id}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_PASSWORD,
          payload: res.data.data,
        });
        Toast("success", "Super Admin password Update Successful");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      Toast("error", error.message);
    });
};