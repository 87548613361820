import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseURL } from "../../../util/Config";
import { apiInstanceFetch } from "../../../util/api";
import { Link } from "react-router-dom/cjs/react-router-dom";
import $ from "jquery";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DateRangePicker } from "react-date-range";
import dayjs from "dayjs";
import { Tooltip } from "antd";
import Pagination from "../../../pages/Pagination";
import { useDispatch } from "react-redux";
import { getHostLiveHistory } from "../../../store/hostLiveHistory/action";
import { useSelector } from "react-redux";

//image
import Male from "../../../assets/images/male.png";

const HostLiveHistory = (props) => {
  const maxDate = new Date();
  const startOfMonth = dayjs().startOf('month').format("YYYY-MM-DD");
  const endOfMonth = dayjs().endOf('month').format("YYYY-MM-DD");

  const [date, setDate] = useState([
    {
      startDate: dayjs().startOf('month').toDate(),
      endDate: dayjs().endOf('month').toDate(),
      key: "selection",
    },
  ]);
  const [sDate, setsDate] = useState(startOfMonth);
  const [eDate, seteDate] = useState(endOfMonth);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const { hostLiveHistory } = useSelector((state) => state.hostLiveHistory);
  const dispatch = useDispatch();

  console.log("hostLiveHistory", hostLiveHistory);

  useEffect(() => {
    dispatch(getHostLiveHistory(startOfMonth, endOfMonth));
  }, [dispatch]);

  const collapsedDatePicker = () => {
    console.log("clicked");
    $("#datePicker").toggleClass("collapse");
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const handleFetchData = () => {
    const dayStart = dayjs(date[0].startDate).format("YYYY-MM-DD");
    const dayEnd = dayjs(date[0].endDate).format("YYYY-MM-DD");
    setsDate(dayStart);
    seteDate(dayEnd);
    dispatch(getHostLiveHistory(dayStart, dayEnd));
  };

  const indexOfLastRow = activePage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = hostLiveHistory ? hostLiveHistory.slice(indexOfFirstRow, indexOfLastRow) : [];

  const capitalizeFirstLetter = (string) => {
    if (!string) return "-";
    return string.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-light" style={{ color: "#e4eeff" }}>
              Host
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  Host livehistory
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                    <button className="btn btn-info" style={{ marginRight: 5 }}>
                      All
                    </button>
                    <button
                      className="collapsed btn btn-info ml-5"
                      value="check"
                      data-toggle="collapse"
                      data-target="#datePicker"
                      onClick={collapsedDatePicker}
                    >
                      Analytics
                      <ExpandMoreIcon />
                    </button>
                    <p style={{ paddingLeft: 10 }} className="my-2 ">
                      {sDate !== "ALL" && sDate + " to " + eDate}
                    </p>
                  </div>
                </div>
                <div
                  id="datePicker"
                  className="collapse mt-5 pt-5"
                  aria-expanded="false"
                >
                  <div className="container table-responsive">
                    <div key={JSON.stringify(date)}>
                      <DateRangePicker
                        maxDate={maxDate}
                        onChange={(item) => {
                          const { startDate, endDate } = item.selection;
                          if (startDate && endDate) {
                            setDate([item.selection]);
                            setsDate(dayjs(startDate).format("YYYY-MM-DD"));
                            seteDate(dayjs(endDate).format("YYYY-MM-DD"));
                          }
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        direction="horizontal"
                      />
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={handleFetchData}
                    >
                      Fetch Data
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow pt-0">
              <table class="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>UniqueId</th>
                    <th>Audio Duration (Minutes)</th>
                    <th>Valid Days (Audio)</th>
                    <th>Video Duration (Minutes)</th>
                    <th>Valid Days (Video)</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRows?.length > 0 ? (
                    currentRows.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{indexOfFirstRow + index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data?.host?.image ? data?.host?.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                              className="mx-auto"
                            />
                          </td>
                          <td>{data?.host?.name ? capitalizeFirstLetter(data?.host?.name.length > 15 ? data?.host?.name.substring(0, 15) + "..." : data?.host?.name) : "-"}</td>
                          <td>{data?.host?.uniqueId ? data?.host?.uniqueId : "-"}</td>
                          <td className="text-danger">
                            {data?.audioData?.totalLiveDuration}
                          </td>
                          <td>{data?.audioData?.validCount}</td>
                          <td className="text-danger">
                            {data?.videoData?.totalLiveDuration}
                          </td>
                          <td>{data?.videoData?.validCount}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={hostLiveHistory?.length || 0}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostLiveHistory;
