import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
// react dropzone
import ReactDropzone from "react-dropzone";
import { baseURL } from "../../util/Config";
import {
  updateAdmissionSVGA,
  crateAdmissionSVGA,
  crateEffectToUser,
} from "../../store/AdmissionCar/action";
import { CLOSE_DIALOGUE_ADMISSION_CAR, OPEN_DIALOGUE_ADMISSION_CAR } from "../../store/AdmissionCar/type";

import SVGA from "svgaplayerweb";
import { Typography } from "antd";
import html2canvas from "html2canvas";
import {
  CropperRef,
  Cropper,
  CropperPreviewWrapper,
} from "react-advanced-cropper";


const AdmissionCarDialogue = (props) => {
  const { Dialogue, DialogueData, DialogueType } = useSelector(
    (state) => state.admissionSVGA
  );


  const [mongoId, setMongoId] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [images, setImages] = useState("");
  const [errors, setError] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [imageData, setImageData] = useState("");
  const [isSvga, setIsSvga] = useState(true);
  const [image, setImage] = useState();
  const [cropper, setCropper] = useState();
  const imageRef = useRef();

  const dispatch = useDispatch();

  useEffect(
    () => () => {

      setError("");
      setUniqueId("");
      setImages("");
      setIsSvga(true);
      setImageData(null);
      setImagePath(null);
    },
    [Dialogue]
  );

//   useEffect(() => {
//     if (DialogueData) {
//       setMongoId(DialogueData?._id);
//       setName(DialogueData?.name);
//       setImagePath(baseURL + DialogueData?.image);
//       setImageData(baseURL + DialogueData?.image);
//       if (DialogueData?.imageVideo?.split(".")?.pop() === "svga") {
//         setIsSvga(true);
//       }
//     }
//   }, [DialogueData]);

  useEffect(() => {
    console.log("Dialogue", Dialogue, DialogueData);
  }, [DialogueData, Dialogue]);

  const handleInputImage = (e) => {
    setImage("");
    if (e.target.files[0]) {
      Object.assign(e.target.files[0], {
        preview: URL.createObjectURL(e.target.files[0]),
      });
      setImageData(e.target.files[0]);
      setImages(e.target.files[0]);

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
        setError({
          image: "",
        });
      });
      reader.readAsDataURL(e.target.files[0]);
      if (e.target.files[0].name.split(".").pop() === "svga") {
        setIsSvga(true);
      } else {
        setIsSvga(false);
      }
    }
  };

  const closePopup = () => {
    dispatch({
      type: CLOSE_DIALOGUE_ADMISSION_CAR,
      payload: {
        DialogueType: "",
        Dialog: false,
        DialogueData: "",
      },
    });
  };

  const onChange = (cropperRef) => {
    setCropper(cropperRef);
  };

  const handleSubmit = async () => {
    if (!uniqueId) {
      const errors = {};

      if (!uniqueId) errors.uniqueId = "UniqueId is Required";

      return setError({ ...errors });
    } else {
      

        const svgaUserData = {
            userId : uniqueId,
            frameId : DialogueData?._id,
            type : DialogueType
        }
    
       dispatch(crateEffectToUser(svgaUserData));
    
      closePopup();
    }
  };



  return (
    <>
      <Dialog
        open={Dialogue}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <DialogTitle id="responsive-dialog-title w-100">
              <span className="text-danger font-weight-bold h4">
               Give to user
              </span>
            </DialogTitle>

            <IconButton onClick={closePopup}>
              <Tooltip title="Close">
                <Cancel className="text-danger" />
              </Tooltip>
            </IconButton>
          </div>
          <DialogContent style={{ overflowY: "hidden" }}>
            <div style={{ display: "none" }}>
              <Cropper
                defaultCoordinates={{
                  height: 221,
                  left: 77,
                  top: 128,
                  width: 192,
                }}
                src={image}
                onChange={onChange}
                className={"cropper"}
              />
              <img
                ref={imageRef}
                src={image}
                alt="Original"
                style={{ display: "none" }}
              />
            </div>
            <div className="modal-body pt-1 px-1 pb-3">
              <div className="d-flex flex-column">
                <form>
                  <div className="row form-data-body">                
                    <div className="col-md-12 col-12">
                      <div className="form-group mt-2">
                        <label className="text-gray mb-2">UniqueId</label>

                        <input
                          type="number"
                          className="form-control"
                          required=""
                          placeholder="UniqueId"
                          value={uniqueId}
                          onChange={(e) => {
                            setUniqueId(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...errors,
                                uniqueId: "UniqueId is Required!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                uniqueId: "",
                              });
                            }
                          }}
                        />

                        {errors.uniqueId && (
                          <div className="ml-2 mt-1">
                            {errors.uniqueId && (
                              <div className="pl-1 text__left">
                                <span className="text-red">{errors.uniqueId}</span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                
                    
                    <div className="col-12">
                      {imagePath && (
                        <>
                          {!isSvga ? (
                            <img
                              src={imagePath}
                              class="mt-3 rounded float-left mb-2"
                              height="100px"
                              width="100px"
                            />
                          ) : (
                            <>
                              <div
                                id="svga"
                                attr={mongoId}
                                style={{
                                  boxShadow:
                                    "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                  float: "left",
                                  objectFit: "contain",
                                  marginBottom: "28px",
                                  overflow: "hidden",
                                  marginRight: 15,
                                  width: "350px",
                                  height: "350px",
                                }}
                              ></div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="modal-footer ">
                    <div>
                      <button
                        type="button"
                        className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                        onClick={closePopup}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                 
                        className="btn btn-round float__right btn-danger"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </DialogContent>{" "}
        </div>
      </Dialog>
    </>
  );
};

export default connect(null, { crateAdmissionSVGA, updateAdmissionSVGA })(
  AdmissionCarDialogue
);
