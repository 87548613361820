import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import { CLOSE_SUB_ADMIN_DIALOGUE } from "../../store/subAdmin/types";
import { addSubAdmin, updateSubAdmin } from "../../store/subAdmin/action";
import {
  addSuperAdmin,
  getSuperAdmin,
  updateSuperAdmin,
} from "../../store/superAdmin/action";
import { baseURL } from "../../util/Config";

const SuperAdminDialogue = () => {
  const { dialog: open, dialogData } = useSelector((state) => state.subAdmin);

  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [paymentGateway, setPaymentGateway] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [imagePath, setImagePath] = useState(null);
  const [code, setCode] = useState("");

  const dispatch = useDispatch();

  const [errors, setError] = useState({
    email: "",
    password: "",
    image: "",
    country: "",
    name: "",
    code: "",
  });


  useEffect(
    () => () => {
      setError({
        email: "",
        password: "",
        image: "",
        country: "",
        name: "",
        code: "",
      });
      setEmail("");
      setPassword("");
      setCountry("");
      setImageData([]);
      setImagePath(null);
      setName("");
      setCode("");
    },
    [open]
  );

  useEffect(() => {
    if (dialogData) {
      setEmail(dialogData?.data?.email);
      setPassword(dialogData?.data?.password);
      setImagePath(baseURL + dialogData?.data?.image);
      setCountry(dialogData?.data?.country);
      setName(dialogData?.data?.name);
      setCode(dialogData?.data?.code);
    }
  }, [dialogData]);

  const closePopup = () => {
    dispatch({ type: CLOSE_SUB_ADMIN_DIALOGUE });
  };

  const handleUpdate = () => {
    
    if (!email || !imagePath || !name) {
      let error = {};
      if (!email) error.email = "Email Is Required !";
      if (!name) error.name = "Name Is Required !";
      if (!imagePath) error.image = "Please select an Image!";
      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("image", imageData);
      formData.append("email", email);
      formData.append("name", name);
      dispatch(updateSuperAdmin(formData, dialogData?.data?._id));
      setTimeout(() => {
        dispatch(getSuperAdmin("All"));
      }, 600);
    }
    closePopup();
  };

  const handleSubmit = () => {
    if (!email || !password || !imagePath || !country || !name || !code) {
      let error = {};

      if (!email) error.email = "Email Is Required !";
      if (!name) error.name = "Name Is Required !";
      if (!password) error.password = "Password Is Required !";
      if (!country) error.country = "Country Is Required !";
      if (!imagePath) {
        error.image = "Please select an Image!";
      }
      if (!code) {
        error.code = "Code can't be a blank!";
      }

      if (!email.includes("@")) {
        error.email = "Email is invalid!";
      }

      return setError({ ...error });
    } else {
      //   const data = {
      //     email,
      //     password: parseInt(password),
      //     roles: paymentGateway,
      //   };

      //   if (dialogData) {
      //     const updateData = {
      //       ...dialogData,
      //       ...data,
      //     };
      //     dispatch(updateSubAdmin(updateData, dialogData?._id));
      //   }

      if (code.toString().length > 4) {
        return setError({
          ...errors,
          code: "Maximum 4 Digits are Allowed!",
        });
      }

      if (code.toString().length < 4) {
        return setError({
          ...errors,
          code: "Minimum 4 Digits are Allowed!",
        });
      }

      const formData = new FormData();

      formData.append("image", imageData);
      formData.append("country", country);
      formData.append("email", email);
      formData.append("name", name);
      formData.append("password", password);
      formData.append("code", code);

      // formData.append("name", name);
      // formData.append("uniqueId", uniqueId);
      // formData.append("bdCode", code);
      // formData.append("bankDetails", bankDetail);

      dispatch(addSuperAdmin(formData));
    }

    closePopup();
  };

  const handleInputImage = (e) => {
    if (e.target.files[0]) {
      setImageData(e.target.files[0]);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    if (!e.target.files[0]) {
      return setError({
        ...errors,
        image: "Please select an Image!",
      });
    } else {
      return setError({
        ...errors,
        image: "",
      });
    }
  };

  const createCode = () => {
    const randomChars = "0123456789";
    let code_ = "";
    for (let i = 0; i < 4; i++) {
      code_ += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
      setCode(code_);
    }
    if (!code_) {
      return setError({
        ...errors,
        code: "Code can't be a blank!",
      });
    } else {
      return setError({
        ...errors,
        code: "",
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        sx={{ maxWidth: "100%", margin: "0 auto" }}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Super Admin </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Enter email"
                      required
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            email: "email can't be a blank!",
                          });
                        } else if (!e.target.value.includes("@")) {
                          setError({
                            ...errors,
                            email: "Invalid email! Must include '@'.",
                          });
                        } else {
                          return setError({
                            ...errors,
                            email: "",
                          });
                        }
                      }}
                    />
                    {errors.email && (
                      <div className="ml-2 mt-1">
                        {errors.email && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.email}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {!dialogData && (
                    <div class="form-group col-12 mt-3">
                      <label class="mb-2 text-gray">Country</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter country"
                        required
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              country: "Country can't be a blank!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              country: "",
                            });
                          }
                        }}
                      />
                      {errors.country && (
                        <div className="ml-2 mt-1">
                          {errors.country && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.country}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div class="form-group col-12 mt-3">
                    <label class="mb-2 text-gray">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Name"
                      required
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            name: "Name can't be a blank!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            name: "",
                          });
                        }
                      }}
                    />
                    {errors.name && (
                      <div className="ml-2 mt-1">
                        {errors.name && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.name}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-12 mt-4">
                    <label className="mb-2 text-gray">Image</label>
                    <input
                      type="file"
                      className="form-control form-control-sm"
                      accept="image/jpg ,image/jpeg ,image/png"
                      required=""
                      onChange={handleInputImage}
                    />
                    {errors.image && (
                      <div className="ml-2 mt-1">
                        {errors.image && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.image}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-12  mt-4">
                    {imagePath && (
                      <>
                        <img
                          height="70px"
                          width="70px"
                          alt="app"
                          src={imagePath}
                          style={{
                            boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                            // border: "2px solid #fff",
                            borderRadius: 10,
                            marginTop: 10,
                            float: "left",
                            objectFit: "cover",
                          }}
                        />
                      </>
                    )}
                  </div>
                  {!dialogData && (
                    <div class="row d-flex mt-3">
                      <div class={`col-md-12`}>
                        <div class="form-group">
                          <label class="mb-2 text-gray">Password</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...errors,
                                  password: "password can't be a blank!",
                                });
                              } else {
                                return setError({
                                  ...errors,
                                  password: "",
                                });
                              }
                            }}
                          />
                          {errors.password && (
                            <div className="ml-2 mt-1">
                              {errors.password && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.password}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {!dialogData && (
                    <div class="row d-flex mt-4">
                      <div class={`${"col-md-9"}`}>
                        <div class="form-group">
                          <label class="mb-2 text-gray">Code</label>
                          <input
                            readOnly
                            type="number"
                            class="form-control"
                            placeholder="Enter Code"
                            required
                            value={code}
                            onKeyPress={handleKeyPress}
                            onChange={(e) => {
                              setCode(e.target.value);

                              if (!e.target.value) {
                                return setError({
                                  ...errors,
                                  code: "Code can't be a blank!",
                                });
                              } else {
                                return setError({
                                  ...errors,
                                  code: "",
                                });
                              }
                            }}
                          />
                          {errors.code && (
                            <div className="ml-2 mt-1">
                              {errors.code && (
                                <div className="pl-1 text__left">
                                  <span className="text-red">
                                    {errors.code}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        class="col-md-3 pl-0 d-flex justify-content-end align-items-center"
                        style={{ marginTop: "22.01px" }}
                      >
                        <button
                          type="button"
                          class="btn btn-info"
                          style={{
                            borderRadius: 5,
                            fontSize: "14px",
                            padding: "8px",
                          }}
                          onClick={createCode}
                        >
                          Auto Generate
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className={"mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={() =>
                      dialogData ? handleUpdate() : handleSubmit()
                    }
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SuperAdminDialogue;
