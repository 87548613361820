import { GET_HOST_LIVE_HISTORY } from "./type";
import { apiInstanceFetch } from "../../util/api";
import { Toast } from "../../util/Toast";

export const getHostLiveHistory = (sDate, eDate) => (dispatch) => {
  apiInstanceFetch
    .get(`hostLiveHistory?startDate=${sDate}&endDate=${eDate}`)
    .then((res) => {
      dispatch({ type: GET_HOST_LIVE_HISTORY, payload: res.data });
    })
    .catch((error) => Toast("error", error.message));
};
