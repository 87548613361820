import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {

  enableDisableBD,
  getBd,
  redeemEnableBd,
  
} from "../store/bdTarget/action";

import { Link, Tooltip } from "@material-ui/core";
import ArraySort from "array-sort";
import Male from "../assets/images/male.png";
import Pagination from "./Pagination";
import { OPEN_BD_DIALOG } from "../store/bdTarget/types";
import { alert, warning } from "../util/Alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import dayjs from "dayjs";
import BdDialogue from "../component/table/BdDialogue";
import $ from "jquery";
import SwitchAdmin from "../component/dialog/SwitchAdmin";
import { OPEN_dialog } from "../store/dialog/type";

const Bd = () => {
  const dispatch = useDispatch();
  const {dialog ,type, dialogData} = useSelector((state) => state.dialog);
  const [data, setData] = useState([]);
  const history = useHistory();
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getBd(activePage, rowsPerPage));
  }, [activePage, rowsPerPage]);

  const { bd, total } = useSelector((state) => state.bd);

  useEffect(() => {
    setData(bd);
  }, [bd]);

  //   pagination

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", Male);
    });
  });

  const handleSearch = (e) => {
    const value = e?.target?.value.toString()
      ? e?.target?.value?.trim()?.toString()
      : e?.target?.value?.trim();

    if (value) {
      const data = bd.filter((data) => {
        return (
          data?.name?.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          data?.uniqueId?.toString()?.indexOf(value) > -1 ||
          data?.bdCode?.toString()?.indexOf(value) > -1 ||
          data?.totalCoin?.toString()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(bd);
    }
  };

  const handleOpen = () => {
    dispatch({ type: OPEN_BD_DIALOG });
  };

  const handleDelete = (planId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          //   dispatch(enableDisableAgency(planId));
          alert("Deleted!", `Plan has been deleted!`, "success");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (data) => {
    dispatch({ type: OPEN_BD_DIALOG, payload: data });
  };

  const handleIsTop = (id) => {
    dispatch(enableDisableBD(id));
  };
  const handleRedeemEnable = (id) => {
    dispatch(redeemEnableBd(id));
  };


  const handleAdminSwitch = (data) => {
    dispatch({ type: OPEN_dialog, payload:{ dialogData:data ,type:"switchAdmin"}});
  }
  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">BD</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  BD
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <button
                    type="button"
                    className="btn waves-effect waves-light btn-danger btn-sm float-left"
                    onClick={handleOpen}
                    id="bannerDialog"
                  >
                    <i className="fa fa-plus"></i>
                    <span className="icon_margin">New</span>
                  </button>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={(e) => {
                          if (e.target.value.length === 0) {
                            handleSearch(e);
                            setActivePage(1);
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSearch(e);
                            setActivePage(1);
                          }
                        }}
                      />
                      <div
                        className="input-group-prepend border-0"
                        for="searchBar"
                        onClick={() => {
                          // Use setSearch with the value of the input field
                          setSearch(document.getElementById("searchBar").value);
                          setActivePage(1);
                        }}
                      >
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table class="table table-striped">
                <thead className="text-center">
                  <tr>
                    <th>No.</th>
                    <th>BD Image</th>
                    <th>BD Name</th>
                    <th>UniqueId</th>
                    <th>BD Code</th>
                    <th>Admin Code</th>

                    <th>Total Coin</th>
                    <th>Created At </th>
                    <th>Is Active</th>

                    <th>Action</th>
                    <th>Agency</th>
                    <th>Shift/Assign Admin</th>
                    
                  </tr>
                </thead>
                <tbody className="text-center">
                  {data?.length > 0 ? (
                    data.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{(activePage - 1) * rowsPerPage + index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data?.image ? data?.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                              className="mx-auto"
                            />
                          </td>
                          <td>
                            {" "}
                            <span className="ms-2 d-flex align-items-center">
                              {data?.name}
                            </span>
                          </td>
                          <td>{data?.uniqueId}</td>
                          <td>{data?.bdCode}</td>
                          <td>
                            {data?.superAdmin && data?.superAdmin !== null
                              ? data?.superAdmin?.code
                              : "-"}
                          </td>

                          <td className="text-success">
                            {data?.totalCoin ? data?.totalCoin : 0}
                          </td>
                          <td>
                            {dayjs(data?.createdAt).format("DD MMM, YYYY")}
                          </td>

                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data?.isActive}
                                onChange={() => handleIsTop(data?._id)}
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${
                                      data?.isActive ? "-24px" : "35px"
                                    }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data?.isActive ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td>

                          <td>
                            <Tooltip title="Edit">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => handleEdit(data)}
                              >
                                <i className="fa fa-edit fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <Tooltip title="Host">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-success d-flex align-items-center"
                                  style={{ backgroundColor: "#fc9494" }}
                                  onClick={() =>
                                    history.push({
                                      pathname: "/admin/bd/bdWiseAgency",
                                      state: data,
                                    })
                                  }
                                >
                                  <i
                                    class="material-icons"
                                    style={{ fontSize: "20px" }}
                                  >
                                    people
                                  </i>
                                </button>
                              </Tooltip>
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() => handleAdminSwitch(data)}
                            >
                              Admin
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={total}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <BdDialogue />
      { dialog && type === "switchAdmin" && <SwitchAdmin /> }
    </>
  );
};
export default Bd;
